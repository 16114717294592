import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { kebabCase } from "lodash"
import localize from "../components/localize"
import tw from "twin.macro"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"

export const query = graphql`
  {
    allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          title
          mainImage {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          publishedAt(locale: "es", formatString: "LL")
          categories {
            title {
              _type
              es
              pt
              en
            }
            slug {
              current
            }
            _id
          }
          _createdAt(locale: "es", formatString: "LL")
          slug {
            current
          }
        }
      }
    }
    allSanityCategory {
      edges {
        node {
          id
          title {
            _key
            _type
            es
            pt
            en
          }
          description {
            _key
            _type
            es
            pt
            en
          }
          slug {
            current
          }
        }
      }
    }
  }
`

const BlogPage = ({ data, pageContext, location }) => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        <body className="invert-nav" />
      </Helmet>
      <SEO
        title="Blog"
        description={intl.formatMessage({ id: "categories" })}
      />
      <div className="grid max-w-6xl gap-6 mx-auto mt-12 lg:grid-cols-4">
        <div tw="pt-24 w-full px-2 mb-6">
          <div className="w-full pt-0 lg:mt-12 lg:top-24 lg:sticky">
            <h1 tw="text-gray-900 my-12 font-serif text-left text-4xl">Blog</h1>
            <div className="flex flex-wrap justify-start w-full lg:flex-col">
              {data.allSanityCategory.edges.map(({ node: article }) => (
                <div key={article.id} className="mb-6 ">
                  <Link
                    to={`/blog/categoria/${kebabCase(article.slug.current)}`}
                    className="transition-all duration-300 transform-gpu"
                    tw="mr-2 font-sans text-sm font-bold py-2 px-2 lg:px-0 text-gray-900 hover:border-gray-700 border-b"
                  >
                    {article.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Section className="mx-auto lg:mt-24 lg:col-span-3 max-w-7xl">
          <div className="grid w-full gap-3 lg:grid-cols-1 ">
            {data.allSanityPost.edges.map(({ node: post }) => (
              <Link
                to={`/blog/${kebabCase(post.slug.current)}`}
                className="relative flex items-center justify-center h-64 overflow-hidden transition-all duration-700 ease-in-out transform bg-gray-900 blog-item"
              >
                <figure className="absolute inset-0 w-full h-64 overflow-hidden opacity-30 ">
                  {post.mainImage && (
                    <Img
                      fluid={post.mainImage.asset.fluid}
                      className="w-full h-64 object-full"
                    />
                  )}
                </figure>
                <div className="relative z-50 w-full px-3 ">
                  <time className="w-full px-4 py-1 mt-2 ml-2 font-mono text-xs font-bold text-right text-gray-100 lg:text-base opacity-90">
                    Públicado el {post.publishedAt}
                  </time>
                  <h4 className="px-6 mt-6 font-sans text-xl font-bold tracking-wider text-left text-white no-underline transition-all duration-500 transform-gpu md:text-3xl hover:opacity-70">
                    {post.title}
                  </h4>
                  <div className="flex flex-col items-start justify-start px-6 mt-4">
                    {post.categories.map(({ title, slug, _id }) => (
                      <Link
                        key={_id}
                        className="inline-block mb-1 font-mono text-xs font-bold text-left text-gray-100 uppercase transition-all duration-500 md:text-base transform-gpu hover:opacity-80 hover:text-gray-300"
                        to={`/blog/categoria/${kebabCase(slug.current)}`}
                      >
                        « {title} »
                      </Link>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Section>
      </div>
    </Layout>
  )
}

export default localize(BlogPage)

const Section = styled.section`
  ${tw`flex flex-col w-full pb-12 mx-auto`}

  .blog-item {
    img {
      transition: all 700ms !important;
      transform: scale(1) !important;
    }

    &:hover {
      img {
        transform: scale(1.05) !important;
      }
    }
  }
`
